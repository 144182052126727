// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const URL = 'https://portal.kropf.np.isccloud.io';
const STAGE = 'kropf';

export const environment = {
  production: false, //either true or false
  NG_APP_COGNITO_USERPOOL_ID: 'us-east-2_AxvzKUTEr',
  NG_APP_COGNITO_CLIENT_ID: '1mlq1kee9250tfshn9q4bok4ee',
  NG_APP_COGNITO_REGION: 'us-east-2',
  APP_URL: `${URL}`,
  API_URL: `${URL}/v3/api`,
  STAGE: `${STAGE}`,
  ICCA_STAGE: 'portal-kropf',
  ICCA_ACCOUNT: 'workload-nonprod-ircl',
  ICCA_REGIONS: ['us-east-1'],
  SQLAAS_URL: '#sqlaas_url#',
  ENABLE_DR: true,
};
